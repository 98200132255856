import { yupResolver } from '@hookform/resolvers/yup'
import { Fragment, ReactNode, useState } from 'react'
import { useForm } from 'react-hook-form'
import {
    Button,
    Dialog,
    DialogClose,
    DialogTrigger,
    Flex,
    Form,
    Input,
    MessageDialog,
    MessageDialogType,
    RadixDialogContent,
    Text
} from 'ui'
import * as yup from 'yup'
import { LoaderIcon } from '../Svgs'

type FormData = {
    name: string
}

const schema = yup
    .object({
        name: yup.string().required('Required')
    })
    .required()

type Props = {
    isOpen: boolean
    title: string
    description: string
    labelName: string
    children?: ReactNode
    initialName?: string
    maxInputLength?: number
    submitText?: string
    submittingText?: string
    submitAction(name: string): void
    handleClose(): void
    handleOpenDialog(): void
}

export const CreateEditDialog = ({
    title,
    labelName,
    description,
    isOpen,
    children,
    initialName,
    maxInputLength,
    submitText = 'create',
    submittingText = 'Creating',
    submitAction,
    handleOpenDialog,
    handleClose
}: Props) => {
    const [messageDialog, setMessageDialog] =
        useState<MessageDialogType>(undefined)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const {
        register,
        reset,
        handleSubmit,
        watch,
        formState: { errors }
    } = useForm<FormData>({
        defaultValues: {
            name: initialName
        },
        resolver: yupResolver(schema)
    })

    const initialNameValue = watch('name')

    const handleCloseDialog = () => {
        handleClose()
        isSubmitting && setIsSubmitting(false)
        reset()
    }

    const onSubmit = handleSubmit(async (data) => {
        try {
            setIsSubmitting(true)

            await submitAction(data.name)

            reset()
            setIsSubmitting(false)
            handleClose()
        } catch (e: any) {
            // may be Jack can take over this type fix
            const code = e.response.status
            let message

            setIsSubmitting(false)

            if (code === 422) {
                message = `The email and password doesn't match.`
            } else {
                message = `Failed to authenticate.`
            }

            setMessageDialog({
                title: `Error`,
                description: message
            })
        }
    })

    const handleCloseMessageDialog = () => {
        setIsSubmitting(false)
        setMessageDialog(undefined)
        reset()
    }

    return (
        <Dialog open={isOpen}>
            {children && (
                <DialogTrigger onClick={handleOpenDialog} asChild>
                    {children}
                </DialogTrigger>
            )}
            <RadixDialogContent
                title={title}
                description={description}
                handleCloseDialog={handleCloseDialog}
            >
                <Form onSubmit={onSubmit}>
                    <Input
                        id="name-input"
                        type="text"
                        label={labelName}
                        maxLength={maxInputLength && maxInputLength}
                        containerCss={{
                            mb: '$3'
                        }}
                        error={errors.name ? true : false}
                        errorMsg={errors.name?.message}
                        {...register('name')}
                        autoFocus
                    />
                    <Flex
                        css={{
                            height: '0rem',
                            position: 'relative',
                            bottom: '30rem',
                            left: '5rem'
                        }}
                        justifyContent={'flexStart'}
                        alignItems={'flexStart'}
                    >
                        {maxInputLength &&
                        !errors.name &&
                        initialNameValue &&
                        initialNameValue.length ? (
                            <Text
                                size={'2'}
                                textAlign={'left'}
                                css={{ width: '100%' }}
                            >
                                {maxInputLength - initialNameValue.length}{' '}
                                characters left
                            </Text>
                        ) : null}
                    </Flex>
                    <Flex>
                        <DialogClose asChild onClick={handleCloseDialog}>
                            <Button
                                color="secondary"
                                size="lg"
                                css={{
                                    mr: '10rem'
                                }}
                            >
                                cancel
                            </Button>
                        </DialogClose>

                        <Button
                            type="submit"
                            color={isSubmitting ? 'loading' : 'primary'}
                            size="lg"
                        >
                            {isSubmitting ? (
                                <Fragment>
                                    <LoaderIcon /> {submittingText}...
                                </Fragment>
                            ) : (
                                submitText
                            )}
                        </Button>
                    </Flex>
                </Form>
            </RadixDialogContent>
            <MessageDialog
                messageDialog={messageDialog}
                handleCloseDialog={handleCloseMessageDialog}
            />
        </Dialog>
    )
}

export const useCreateEditDialog = (): {
    openDialog: boolean
    setOpenDialog(val: boolean): void
    handleOpenDialog(): void
    handleCloseDialog(): void
} => {
    const [openDialog, setOpenDialog] = useState(false)

    const handleOpenDialog = () => {
        setOpenDialog(true)
    }

    const handleCloseDialog = () => {
        setOpenDialog(false)
    }

    return { openDialog, setOpenDialog, handleOpenDialog, handleCloseDialog }
}
