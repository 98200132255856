import { SVGProps } from 'react'

export const ArrowDown = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 18 18"
        {...props}
    >
        <title>down arrow icon</title>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9 12.938 4.5 7.311h9L9 12.938Z"
            fill="#7E8CA4"
        />
    </svg>
)
