import * as AccordionPrimitive from '@radix-ui/react-accordion'
import { keyframes } from '@stitches/react'
import { ComponentProps, ElementRef, forwardRef } from 'react'
import { CSS, styled } from '../../theme/stitches.config'
import { ArrowUp } from '../Svgs'

const slideDown = keyframes({
    from: { height: 0 },
    to: { height: 'var(--radix-accordion-content-height)' }
})

const slideUp = keyframes({
    from: { height: 'var(--radix-accordion-content-height)' },
    to: { height: 0 }
})

export const AccordionRoot = styled(AccordionPrimitive.Root, {
    width: '100%'
})
export const AccordionItem = styled(AccordionPrimitive.Item, {
    overflow: 'hidden'
})

const StyledHeader = styled(AccordionPrimitive.Header, {
    display: 'flex'
})

const StyledTrigger = styled(AccordionPrimitive.Trigger, {
    backgroundColor: 'transparent',
    p: '15rem 0',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    color: '$white',
    textTransform: 'uppercase',
    fontWeight: '$bold',
    fontSize: '$3'
})

const StyledArrowUpSvg = styled(ArrowUp, {
    height: '20rem',
    ml: 'auto',
    display: 'flex',
    flexShrink: '0',
    transform: 'rotate(180deg)',

    '[data-state="open"] &': {
        transform: 'none'
    }
})

const TRIGGER_DEFAULT_TAG = 'button'
type AccordionTriggerProps = ComponentProps<typeof TRIGGER_DEFAULT_TAG> & {
    css?: CSS
    as?: any
}

export const AccordionTrigger = forwardRef<
    ElementRef<typeof TRIGGER_DEFAULT_TAG>,
    AccordionTriggerProps
>(({ children, ...props }, forwardedRef) => (
    <StyledHeader>
        <StyledTrigger {...props} ref={forwardedRef}>
            {children} <StyledArrowUpSvg />
        </StyledTrigger>
    </StyledHeader>
))

AccordionTrigger.displayName = 'AccordionTrigger'

const StyledContent = styled(AccordionPrimitive.Content, {
    overflow: 'hidden',

    '&[data-state="open"]': {
        animation: `${slideDown} 300ms cubic-bezier(0.87, 0, 0.13, 1)`
    },
    '&[data-state="closed"]': {
        animation: `${slideUp} 300ms cubic-bezier(0.87, 0, 0.13, 1)`
    }
})

const CONTENT_DEFAULT_TAG = 'div'
type AccordionContentProps = ComponentProps<typeof CONTENT_DEFAULT_TAG> & {
    css?: CSS
    as?: any
}

export const AccordionContent = forwardRef<
    ElementRef<typeof CONTENT_DEFAULT_TAG>,
    AccordionContentProps
>(({ children, ...props }, forwardedRef) => (
    <StyledContent {...props} ref={forwardedRef}>
        {children}
    </StyledContent>
))

AccordionContent.displayName = 'AccordionContent'

const StyledContainer = styled('button', {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
})

const VIDEO_FOLDER_DEFAULT_TAG = 'button'
type FolderAccordionVideoFolderProps = {
    active?: boolean
} & ComponentProps<typeof VIDEO_FOLDER_DEFAULT_TAG> & {
        css?: CSS
        as?: any
    }

export const AccordionContainer = ({
    children,
    active,
    ...props
}: FolderAccordionVideoFolderProps) => {
    return (
        <StyledContainer type="button" {...props} data-active={active}>
            {children}
        </StyledContainer>
    )
}
