import { truncate } from 'lodash'
import Link from 'next/link'
import { Fragment } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Flex, Text } from 'ui'
import { styled } from 'ui/theme/stitches.config'

export interface BreadcrumbListItem {
    name: string
    link?: string
    onClick?: () => void
}

type Props = {
    breadcrumbList: BreadcrumbListItem[]
}

export const Breadcrumb = ({ breadcrumbList }: Props) => {
    const isDesktop = useMediaQuery({ query: '(min-width: 768px)' })
    if (!isDesktop) {
        for (const breadcrumb of breadcrumbList) {
            breadcrumb.name = truncate(breadcrumb.name, { length: 18 })
        }
        if (breadcrumbList.length > 2) {
            breadcrumbList.splice(0, breadcrumbList.length - 2, {
                name: '. . .'
            })
        }
    }

    return (
        <Flex justifyContent="flexStart">
            {breadcrumbList.map((item: BreadcrumbListItem, index: number) => {
                const currentIndex = index + 1
                if (breadcrumbList.length !== currentIndex) {
                    return (
                        <Fragment key={`breadcrumb-${currentIndex}`}>
                            {item.link ? (
                                <NextLink
                                    href={item.link}
                                    onClick={item.onClick}
                                >
                                    <Text
                                        size={'3'}
                                        weight="bold"
                                        textTransform="upper"
                                        color="cove"
                                    >
                                        {item.name}
                                    </Text>
                                </NextLink>
                            ) : (
                                <BareLink onClick={item.onClick}>
                                    <Text
                                        size="3"
                                        weight="bold"
                                        textTransform="upper"
                                        color="cove"
                                    >
                                        {item.name}
                                    </Text>
                                </BareLink>
                            )}

                            <Text
                                color="cove"
                                weight="light"
                                css={{
                                    m: '0 $2'
                                }}
                            >
                                /
                            </Text>
                        </Fragment>
                    )
                }

                return (
                    <Text
                        key={`breadcrumb-${currentIndex}`}
                        size="3"
                        weight="bold"
                        textTransform="upper"
                        color="sky"
                    >
                        {item.name}
                    </Text>
                )
            })}
        </Flex>
    )
}

const BareLink = styled('a', {
    textDecoration: 'none',
    cursor: 'pointer'
})

const NextLink = styled(Link, {
    textDecoration: 'none',
    cursor: 'pointer'
})
