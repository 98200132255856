import { keyframes } from '@stitches/react'
import { styled } from 'ui/theme/stitches.config'

const rotate = keyframes({
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(360deg)' }
})

const Spinner = styled('div', {
    display: 'inline-block',
    position: 'relative',
    width: '47rem',
    height: '47rem',
    div: {
        boxSizing: 'border-box',
        display: 'block',
        position: 'absolute',
        width: '35rem',
        height: '35rem',
        border: '4px solid #fff',
        borderRadius: '50%',
        animation: `${rotate} 1s infinite`,
        animationTimingFunction: 'linear',
        borderColor: '#fff transparent transparent'
    }
})

export const LoadingSpinner = () => {
    return (
        <Spinner>
            <div></div>
        </Spinner>
    )
}
