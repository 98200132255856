import dynamic from 'next/dynamic'
import { ReactNode } from 'react'

type Props = {
    children: ReactNode
}

const _NoSsr = ({ children }: Props) => <>{children}</>

export const NoSsr = dynamic(() => Promise.resolve(_NoSsr), {
    ssr: false
})
