import LeftArrow from 'share-svgs/svgs/arrow-left.svg'
import RightArrow from 'share-svgs/svgs/arrow-right.svg'
import { HStack, IconButton, Text } from 'ui'

export const AlternatePagination = ({
    total,
    currentPage,
    lastPage,
    from,
    to,
    handleNextPage,
    handlePrevPage
}: {
    total: number
    currentPage: number
    lastPage: number
    from: number
    to: number
    handleNextPage(): void
    handlePrevPage(): void
}) => {
    return (
        <HStack alignItems={'center'} widthAuto>
            <IconButton
                css={{
                    $$iconHeight: '10rem',
                    '&[disabled] svg path': {
                        stroke: '$gray'
                    }
                }}
                disabled={currentPage === 1}
                onClick={handlePrevPage}
            >
                <LeftArrow />
            </IconButton>

            <HStack spacing={'1'}>
                <Text size={'2'} weight={'bold'}>
                    {from} - {to}
                </Text>
                <Text size={'2'}>of {total}</Text>
            </HStack>

            <IconButton
                css={{
                    $$iconHeight: '10rem',
                    '&[disabled] svg path': {
                        stroke: '$gray'
                    }
                }}
                disabled={currentPage === lastPage}
                onClick={handleNextPage}
            >
                <RightArrow />
            </IconButton>
        </HStack>
    )
}
