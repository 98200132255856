import { styled } from '../../theme/stitches.config'
import { keyframes } from '@stitches/react'

const rotate360 = keyframes({
    '50%': {
        transform: 'rotate(180deg)'
    },
    '100%': {
        transform: 'rotate(360deg)'
    }
})

export const Button = styled('button', {
    $$buttonRadius: '6rem',
    $$buttonXlPadding: '12rem 25rem',
    $$buttonLgPadding: '8rem 25rem',
    $$buttonSmPadding: '5rem 18rem',
    $$buttonXsPadding: '0rem',
    //Primary
    $$buttonPrimaryBg: 'linear-gradient(180deg, #63b9d7 0%, #4698b4 100%)',
    $$buttonPrimaryText: '$colors$dark',
    $$buttonPrimaryHoverText: '$colors$blue',
    $$buttonPrimaryHoverBg: '$colors$sky',
    $$buttonPrimaryActiveBg: '$colors$blue',
    $$buttonPrimaryBoxShadow: '$colors$blue',
    //Secondary
    $$buttonSecondaryBg: 'linear-gradient(180deg, #FFFFFF 0%, #E3EBF9 100%)',
    $$buttonSecondaryText: '$colors$dark',
    $$buttonSecondaryHoverText: '$colors$cove',
    $$buttonSecondaryHoverBg: '$colors$white',
    $$buttonSecondaryBoxShadow: '$colors$cove',
    $$buttonSecondaryActiveBg: '$colors$cove',
    //Delete
    $$buttonDeleteBg: 'linear-gradient(180deg, #EF6438 0%, #D94C49 100%)',
    $$buttonDeleteText: '$colors$dark',
    $$buttonDeleteHoverText: '$colors$burnt',
    $$buttonDeleteHoverBg: '$colors$orange',
    $$buttonDeleteBoxShadow: '$colors$burnt',
    $$buttonDeleteActiveBg: '$colors$burnt',
    //Outline
    $$buttonOutlineBg: 'rgba(99, 185, 215, 0.3)',
    $$buttonOutlineBorderColor: '$colors$sky',
    $$buttonOutlineText: '$colors$white',
    $$buttonOutlineHoverText: '$colors$white',
    $$buttonOutlineHoverBg: 'rgba(99, 185, 215, 0.5)',
    $$buttonOutlineActiveBg: '$colors$sky',
    //Link
    $$buttonLinkText: '$colors$sky',

    //Base
    display: 'flex',
    alignItems: 'center',
    textTransform: 'uppercase',
    letterSpacing: '0.1em',
    borderRadius: '$$buttonRadius',
    fontWeight: '$bold',
    whiteSpace: 'nowrap',
    '& svg': {
        display: 'inline-block',
        height: '15rem',
        marginRight: '5rem',

        '& path': {
            fill: '$$buttonPrimaryText'
        }
    },

    '&:disabled': {
        color: '$nobel',
        background: '$dim',
        boxShadow: 'none',
        border: 'none',
        pointerEvents: 'none',

        '& svg path': {
            fill: '$nobel'
        }
    },

    variants: {
        size: {
            xl: {
                padding: '$$buttonXlPadding',
                fontSize: '$3'
            },
            lg: {
                padding: '$$buttonLgPadding',
                fontSize: '$3'
            },
            sm: {
                padding: '$$buttonSmPadding',
                fontSize: '$2'
            },
            xs: {
                padding: '$$buttonXsPadding',
                fontSize: '$1'
            },
            full: {
                width: '100%',
                py: '10rem',
                fontSize: '$2',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }
        },
        color: {
            bare: {},
            primary: {
                background: '$$buttonPrimaryBg',
                color: '$$buttonPrimaryText',
                boxShadow: '0rem 2rem 0rem $$buttonPrimaryBoxShadow',

                '&:hover': {
                    color: '$$buttonPrimaryHoverText',
                    background: '$$buttonPrimaryHoverBg',

                    '& svg path': {
                        fill: '$$buttonPrimaryHoverText'
                    }
                },
                '&:active': {
                    color: '$$buttonPrimaryText',
                    background: '$$buttonPrimaryActiveBg',

                    '& svg path': {
                        fill: '$$buttonPrimaryText'
                    }
                }
            },
            secondary: {
                background: '$$buttonSecondaryBg',
                color: '$$buttonSecondaryText',
                boxShadow: '0rem 2rem 0rem $$buttonSecondaryBoxShadow',

                '&:hover': {
                    color: '$$buttonSecondaryHoverText',
                    background: '$$buttonSecondaryHoverBg',

                    '& svg path': {
                        fill: '$$buttonSecondaryHoverText'
                    }
                },
                '&:active': {
                    color: '$$buttonSecondaryText',
                    background: '$$buttonSecondaryActiveBg',

                    '& svg path': {
                        fill: '$$buttonSecondaryText'
                    }
                }
            },
            delete: {
                background: '$$buttonDeleteBg',
                color: '$$buttonDeleteText',
                boxShadow: '0rem 2rem 0rem $$buttonDeleteBoxShadow',

                '&:hover': {
                    color: '$$buttonDeleteHoverText',
                    background: '$$buttonDeleteHoverBg',

                    '& svg path': {
                        fill: '$$buttonDeleteHoverText'
                    }
                },
                '&:active': {
                    color: '$$buttonDeleteText',
                    background: '$$buttonDeleteActiveBg',

                    '& svg path': {
                        fill: '$$buttonDeleteText'
                    }
                }
            },
            outline: {
                background: '$$buttonOutlineBg',
                border: '1rem solid $$buttonOutlineBorderColor',
                color: '$$buttonOutlineText',

                '& svg path': {
                    fill: '$white'
                },

                '&:hover': {
                    color: '$$buttonOutlineHoverText',
                    background: '$$buttonOutlineHoverBg',

                    '& svg path': {
                        fill: '$$buttonOutlineHoverText'
                    }
                },
                '&:active': {
                    color: '$$buttonOutlineText',
                    background: '$$buttonOutlineActiveBg',

                    '& svg path': {
                        fill: '$$buttonOutlineText'
                    }
                }
            },
            loading: {
                color: '$nobel',
                background: '$dim',
                boxShadow: 'none',
                border: 'none',
                pointerEvents: 'none',

                '& svg path': {
                    fill: '$nobel'
                }
            },
            text: {
                background: 'none'
            }
        },
        fullWidth: {
            true: {
                width: '100%',
                display: 'flex',
                justifyContent: 'center'
            }
        },
        rotateSvg: {
            true: {
                '& svg': {
                    animation: `${rotate360} 1.5s cubic-bezier(0.87, 0, 0.13, 1) infinite`
                }
            }
        }
    },
    defaultVariants: {
        color: 'primary',
        size: 'lg'
    }
})
