import { yupResolver } from '@hookform/resolvers/yup'
import { csrf, useAuth } from 'auth'
import { setCookie } from 'cookies-next'
import { useRouter } from 'next/router'
import { Fragment, useState } from 'react'
import { useForm } from 'react-hook-form'
import {
    AuthFlow,
    Button,
    Flex,
    Form,
    Heading,
    HStack,
    Input,
    MessageDialog,
    MessageDialogType,
    STATEMINTS_APP_NAME,
    Text,
    VAULT_APP_NAME,
    VStack
} from 'ui'
import * as yup from 'yup'
import fetcher from '../../utils/fetcher'
import { LinkButton } from '../Link'
import { LoaderIcon } from '../Svgs'

const NAME_MAX_LENGTH = 50
const EMAIL_MAX_LENGTH = 500
const PASSWORD_MAX_LENGTH = 50

type FormData = {
    firstName: string
    lastName: string
    email: string
    password: string
}

const schema = yup
    .object({
        firstName: yup.string().required('Required').max(NAME_MAX_LENGTH),
        lastName: yup.string().required('Required').max(NAME_MAX_LENGTH),
        email: yup
            .string()
            .email()
            .required('Email is required')
            .max(EMAIL_MAX_LENGTH),
        password: yup
            .string()
            .min(8, 'Minimal 8 characters')
            .max(50)
            .matches(
                new RegExp('(\\p{Ll}+.*\\p{Lu})|(\\p{Lu}+.*\\p{Ll})', 'u'),
                'The password must contain at least one uppercase and one lowercase letter.'
            )
            .required('Password is required')
    })
    .required()

type Props = {
    appName: string
    email?: string
    first_name?: string
    last_name?: string
    setAuthFlow?(view: AuthFlow): void
}

export const SignUpBox = ({
    appName,
    email,
    first_name,
    last_name,
    setAuthFlow
}: Props) => {
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [messageDialog, setMessageDialog] =
        useState<MessageDialogType>(undefined)

    const router = useRouter()
    const { mutate: refreshUser } = useAuth()

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm<FormData>({ resolver: yupResolver(schema) })

    const handleCloseMessageDialog = () => {
        setMessageDialog(undefined)
    }

    const handleLoginRedirect = () => {
        if (router.pathname.includes('/splash') && setAuthFlow) {
            setAuthFlow('login')
            return
        }

        router.push('/login')
    }

    const resetState = () => {
        setMessageDialog(undefined)
        setIsSubmitting(false)
    }

    const authenticateUser = async () => {
        setCookie('isAuthenticated', 'true')
        await refreshUser()
    }

    const onSubmit = handleSubmit(async (data) => {
        try {
            const { token } = router.query

            setIsSubmitting(true)
            setMessageDialog({
                title: 'Signing up...',
                loadingMode: true
            })

            if (appName === VAULT_APP_NAME) {
                await csrf()
                await fetcher.post(`users?token=${token}`, {
                    email: data.email,
                    first_name: data.firstName,
                    last_name: data.lastName,
                    password: data.password
                })
                await authenticateUser()
                await router.replace('/invite/success')

                setIsSubmitting(false)
            }

            if (appName === STATEMINTS_APP_NAME) {
                await csrf()
                await fetcher.post(`users?token=${token}`, {
                    email: data.email,
                    first_name: data.firstName,
                    last_name: data.lastName,
                    password: data.password,
                    client_name: 'Jogg web'
                })
                await authenticateUser()
                setAuthFlow && setAuthFlow('accept-invite')

                setTimeout(() => {
                    resetState()
                }, 500)
            }
        } catch (error: any) {
            setMessageDialog({
                title: `Error`,
                description: error.response.data.message
            })
            setIsSubmitting(false)
        }
    })

    return (
        <Fragment>
            <Form onSubmit={onSubmit}>
                <VStack>
                    <Heading>Create Account</Heading>
                    <HStack spacing="5">
                        <Input
                            id="first-name"
                            type="text"
                            label="First Name"
                            maxLength={NAME_MAX_LENGTH}
                            defaultValue={first_name ? first_name : ''}
                            error={errors.firstName ? true : false}
                            errorMsg={errors.firstName?.message}
                            {...register('firstName')}
                        />
                        <Input
                            id="last-name"
                            type="text"
                            label="Last Name"
                            maxLength={NAME_MAX_LENGTH}
                            defaultValue={last_name ? last_name : ''}
                            error={errors.lastName ? true : false}
                            errorMsg={errors.lastName?.message}
                            {...register('lastName')}
                        />
                    </HStack>

                    <Input
                        id="email"
                        type="text"
                        label="Email"
                        maxLength={EMAIL_MAX_LENGTH}
                        defaultValue={email ? email : ''}
                        error={errors.email ? true : false}
                        errorMsg={errors.email?.message}
                        {...register('email')}
                    />

                    <Input
                        id="password"
                        type="password"
                        label="Password"
                        maxLength={PASSWORD_MAX_LENGTH}
                        error={errors.password ? true : false}
                        errorMsg={errors.password?.message}
                        {...register('password')}
                    />
                </VStack>

                <Flex css={{ my: '$2' }}>
                    <Button
                        type="submit"
                        color={isSubmitting ? 'loading' : 'primary'}
                        rotateSvg={isSubmitting}
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? (
                            <Fragment>
                                <LoaderIcon /> signing up...
                            </Fragment>
                        ) : (
                            'sign up'
                        )}
                    </Button>
                </Flex>
            </Form>

            {appName === STATEMINTS_APP_NAME ? (
                <HStack justifyContent={'center'} spacing={'1'}>
                    <Text textTransform={'upper'} size={'2'}>
                        already have an account?{' '}
                    </Text>
                    <LinkButton
                        type="button"
                        size="sm"
                        weight="normal"
                        onClick={handleLoginRedirect}
                    >
                        login
                    </LinkButton>
                </HStack>
            ) : null}

            <MessageDialog
                messageDialog={messageDialog}
                handleCloseDialog={handleCloseMessageDialog}
            />
        </Fragment>
    )
}
