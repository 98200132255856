import { SVGProps } from 'react'

export const Search = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        {...props}
    >
        <title>search icon</title>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.923 13.923a5.882 5.882 0 0 1-8.308 0 5.883 5.883 0 0 1 0-8.31 5.882 5.882 0 0 1 8.308 0 5.883 5.883 0 0 1 0 8.31ZM3.92 3.92c-3.225 3.226-3.225 8.474 0 11.699 2.666 2.666 6.71 3.122 9.856 1.382l4.835 4.834a2.286 2.286 0 0 0 3.225 0 2.286 2.286 0 0 0 0-3.224L17 13.774c1.74-3.145 1.284-7.19-1.382-9.856-3.225-3.225-8.473-3.225-11.7 0Z"
            fill="#3F94B1"
        />
    </svg>
)
