import * as AccordionPrimitive from '@radix-ui/react-accordion'
import { keyframes } from '@stitches/react'
import { ComponentProps, ElementRef, forwardRef } from 'react'
import { CSS, styled } from '../../theme/stitches.config'
import { ArrowUp, Folder, VideoFolder } from '../Svgs'

const slideDown = keyframes({
    from: { height: 0 },
    to: { height: 'var(--radix-accordion-content-height)' }
})

const slideUp = keyframes({
    from: { height: 'var(--radix-accordion-content-height)' },
    to: { height: 0 }
})

export const FolderAccordion = styled(AccordionPrimitive.Root, {
    width: '100%'
})
export const FolderAccordionItem = styled(AccordionPrimitive.Item, {
    overflow: 'hidden'
})

const StyledHeader = styled(AccordionPrimitive.Header, {
    display: 'flex',
    textAlign: 'left'
})

const StyledTrigger = styled(AccordionPrimitive.Trigger, {
    backgroundColor: 'transparent',
    p: '15rem',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    color: '$white',
    textTransform: 'uppercase',
    fontWeight: '$bold',
    fontSize: '$2',
    textAlign: 'left',

    '&[data-state="open"]': {
        color: '$navy'
    },
    '&:hover': {
        color: '$navy'
    }
})

const StyledFolderSvg = styled(Folder, {
    height: '20rem',
    mr: '$2',
    display: 'flex',
    flexShrink: '0'
})

const StyledArrowUpSvg = styled(ArrowUp, {
    height: '20rem',
    ml: 'auto',
    display: 'flex',
    flexShrink: '0',
    transform: 'rotate(180deg)',

    '[data-state="open"] &': {
        transform: 'none'
    }
})

const TRIGGER_DEFAULT_TAG = 'button'
type AccordionTriggerProps = ComponentProps<typeof TRIGGER_DEFAULT_TAG> & {
    css?: CSS
    as?: any
}

export const FolderAccordionTrigger = forwardRef<
    ElementRef<typeof TRIGGER_DEFAULT_TAG>,
    AccordionTriggerProps
>(({ children, ...props }, forwardedRef) => (
    <StyledHeader>
        <StyledTrigger {...props} ref={forwardedRef}>
            <StyledFolderSvg /> {children} <StyledArrowUpSvg />
        </StyledTrigger>
    </StyledHeader>
))

FolderAccordionTrigger.displayName = 'FolderAccordionTrigger'

const StyledContent = styled(AccordionPrimitive.Content, {
    overflow: 'hidden',

    '&[data-state="open"]': {
        animation: `${slideDown} 300ms cubic-bezier(0.87, 0, 0.13, 1)`
    },
    '&[data-state="closed"]': {
        animation: `${slideUp} 300ms cubic-bezier(0.87, 0, 0.13, 1)`
    }
})

const CONTENT_DEFAULT_TAG = 'div'
type AccordionContentProps = ComponentProps<typeof CONTENT_DEFAULT_TAG> & {
    css?: CSS
    as?: any
}

export const FolderAccordionContent = forwardRef<
    ElementRef<typeof CONTENT_DEFAULT_TAG>,
    AccordionContentProps
>(({ children, ...props }, forwardedRef) => (
    <StyledContent {...props} ref={forwardedRef}>
        {children}
    </StyledContent>
))

FolderAccordionContent.displayName = 'FolderAccordionContent'

const StyledVideoFolderContainer = styled('button', {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',

    '&:hover': {
        backgroundColor: '$ebony'
    },

    '&[data-active="true"]': {
        backgroundColor: '$ebony'
    }
})

const StyledVideoFolderInnerContainer = styled('div', {
    width: '100%',
    ml: '$5',
    p: '10rem 20rem',
    borderLeft: '1rem solid $kashmir',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'left',
    textTransform: 'uppercase',
    fontWeight: '$bold',
    fontSize: '$2'
})

const StyledVideoFolder = styled(VideoFolder, {
    height: '20rem',
    mr: '$2'
})

const VIDEO_FOLDER_DEFAULT_TAG = 'button'
type FolderAccordionVideoFolderProps = {
    active?: boolean
} & ComponentProps<typeof VIDEO_FOLDER_DEFAULT_TAG> & {
        css?: CSS
        as?: any
    }

export const FolderAccordionVideoFolder = ({
    children,
    active,
    ...props
}: FolderAccordionVideoFolderProps) => {
    return (
        <StyledVideoFolderContainer
            type="button"
            {...props}
            data-active={active}
        >
            <StyledVideoFolderInnerContainer>
                <StyledVideoFolder /> {children}
            </StyledVideoFolderInnerContainer>
        </StyledVideoFolderContainer>
    )
}
