import { motion } from 'framer-motion'
import { isEmpty } from 'lodash'
import CreateFolderIcon from 'share-svgs/svgs/create-folder.svg'
import {
    FileButton,
    Flex,
    Grid,
    Heading,
    Pagination,
    Skeletons,
    Text,
    VStack
} from 'ui'
import { SearchFolder } from 'ui/types'

type Props = {
    searchData: {
        data: SearchFolder[]
        meta: {
            last_page: number
        }
    }
    page: number
    handleSetPage(page: { selected: number }): void
    handleFolderRedirection(id: string, name?: string): void
}

export const FoldersTabContent = ({
    searchData,
    handleFolderRedirection,
    page,
    handleSetPage
}: Props) => {
    return (
        <VStack spacing="3" alignItems="flexStart">
            <Heading>Folders</Heading>

            {!searchData ? (
                <Grid
                    as={motion.div}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    columns="4"
                    gap="3"
                >
                    <Skeletons amount={4} height="70rem" />
                </Grid>
            ) : null}

            {searchData &&
            searchData.data &&
            !isEmpty(searchData.data) &&
            searchData.data.length ? (
                <Grid
                    as={motion.div}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    columns={{
                        '@initial': 1,
                        '@md': 4
                    }}
                    gap="3"
                >
                    {searchData.data.map((folder: SearchFolder) => (
                        <FileButton
                            key={folder.id}
                            css={{
                                $$svgWidth: '50rem'
                            }}
                            svg={<CreateFolderIcon />}
                            title={folder.name}
                            onClick={() =>
                                handleFolderRedirection(folder.id, folder.name)
                            }
                        />
                    ))}
                </Grid>
            ) : null}

            {searchData && searchData.data && isEmpty(searchData.data) ? (
                <Text weight="light">There&apos;re no results for Folders</Text>
            ) : null}

            {searchData && searchData.meta && searchData.meta.last_page > 1 ? (
                <Flex css={{ mt: '$9' }}>
                    <Pagination
                        pageCount={searchData.meta.last_page}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={1}
                        forcePage={page - 1}
                        onPageChange={handleSetPage}
                    />
                </Flex>
            ) : null}
        </VStack>
    )
}
