import { styled } from '../../theme/stitches.config'

export const Flex = styled('div', {
    width: '100%',
    display: 'flex',

    variants: {
        direction: {
            row: {
                flexDirection: 'row'
            },
            col: {
                flexDirection: 'column'
            }
        },
        flex: {
            1: {
                flex: '1'
            },
            0: {
                flex: '0'
            }
        },
        size: {
            sm: {
                maxWidth: '$sm'
            },
            md: {
                maxWidth: '$md'
            },
            lg: {
                maxWidth: '$lg'
            }
        },
        flexBasis: {
            auto: {
                flexBasis: 'auto'
            },
            maxContent: {
                flexBasis: 'max-content'
            },
            minContent: {
                flexBasis: 'min-content'
            },
            content: {
                flexBasis: 'content'
            }
        },
        alignItems: {
            center: {
                alignItems: 'center'
            },
            flexStart: {
                alignItems: 'flex-start'
            },
            flexEnd: {
                alignItems: 'flex-end'
            },
            stretch: {
                alignItems: 'stretch'
            }
        },
        justifyContent: {
            flexStart: {
                justifyContent: 'flex-start'
            },
            flexEnd: {
                justifyContent: 'flex-end'
            },
            center: {
                justifyContent: 'center'
            },
            spaceBetween: {
                justifyContent: 'space-between'
            },
            evenly: {
                justifyContent: 'space-evenly'
            },
            around: {
                justifyContent: 'space-around'
            }
        },
        gap: {
            1: {
                gap: '$1'
            },
            2: {
                gap: '$2'
            },
            3: {
                gap: '$3'
            },
            4: {
                gap: '$4'
            },
            5: {
                gap: '$5'
            },
            6: {
                gap: '$6'
            },
            7: {
                gap: '$7'
            },
            8: {
                gap: '$8'
            },
            9: {
                gap: '$9'
            }
        },
        p: {
            1: {
                p: '$1'
            },
            2: {
                p: '$2'
            },
            3: {
                p: '$3'
            },
            4: {
                p: '$4'
            },
            5: {
                p: '$5'
            },
            6: {
                p: '$6'
            },
            7: {
                p: '$7'
            },
            8: {
                p: '$8'
            },
            9: {
                p: '$9'
            }
        },
        pt: {
            1: {
                paddingTop: '$1'
            }
        },
        pb: {
            1: {
                paddingBottom: '$1'
            }
        },
        widthInitial: {
            true: {
                width: 'initial'
            }
        },
        flexWrap: {
            true: {
                flexWrap: 'wrap'
            }
        },
        relative: {
            true: {
                position: 'relative'
            }
        }
    },
    defaultVariants: {
        direction: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    }
})
