import { SVGProps } from 'react'

export const ArrowLeft = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 6 8"
        {...props}
    >
        <path
            d="M5 1.171 2.172 4 5 6.828"
            stroke="#3F94B1"
            strokeWidth={2}
            strokeLinecap="round"
        />
    </svg>
)
