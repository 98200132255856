import { SVGProps } from 'react'

export const ArrowUp = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 18 18"
        {...props}
    >
        <title>arrow up icon</title>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m9 6.188 4.5 5.625h-9L9 6.186Z"
            fill="#5878A5"
        />
    </svg>
)
