import * as SelectPrimitive from '@radix-ui/react-select'
import { ComponentProps, ReactNode } from 'react'
import { CSS, styled } from '../../theme/stitches.config'

export const Select = SelectPrimitive.Root
export const SelectValue = SelectPrimitive.Value

export const SelectTrigger = styled(SelectPrimitive.SelectTrigger, {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '$3',
    height: '35rem',
    p: '10rem 15rem',
    backgroundColor: '$dark_lighter',
    borderRadius: '$1',
    border: '1rem solid $kashmir',
    textTransform: 'capitalize',

    '&[data-placeholder]': {
        color: '$white'
    },

    variants: {
        boldAndUpper: {
            true: {
                textTransform: 'uppercase',
                fontWeight: '$bold',
                fontSize: '$2'
            }
        },
        letterSpacing: {
            true: {
                letterSpacing: '$1'
            }
        }
    }
})
export const SelectIcon = styled(SelectPrimitive.SelectIcon, {
    display: 'flex',
    flexShrink: '0',

    '& svg': {
        width: '20rem'
    }
})

const StyledContent = styled(SelectPrimitive.Content, {
    overflow: 'hidden',
    backgroundColor: '$dark_lighter',
    borderRadius: '$1',
    border: '1rem solid $kashmir',
    zIndex: '999'
})

const DEFAULT_TAG = 'div'
type SelectContentProps = {
    children: ReactNode
    onCloseAutoFocus?(): void
    onEscapeKeyDown?(): void
    onPointerDownOutside?(): void
} & ComponentProps<typeof DEFAULT_TAG> & { css?: CSS; as?: any }

export const SelectContent = ({
    children,
    onCloseAutoFocus,
    onEscapeKeyDown,
    onPointerDownOutside,
    ...props
}: SelectContentProps) => (
    <SelectPrimitive.Portal>
        <StyledContent
            onEscapeKeyDown={onEscapeKeyDown}
            onPointerDownOutside={onPointerDownOutside}
            onCloseAutoFocus={onCloseAutoFocus}
            {...props}
        >
            {children}
        </StyledContent>
    </SelectPrimitive.Portal>
)

export const SelectViewport = styled(SelectPrimitive.Viewport, {
    p: 5
})
export const SelectGroup = SelectPrimitive.Group
export const SelectItem = styled(SelectPrimitive.Item, {
    fontSize: '$3',
    color: '$white',
    display: 'flex',
    alignItems: 'center',
    p: '10rem 15rem',
    position: 'relative',
    userSelect: 'none',
    cursor: 'pointer',
    outline: 'none',
    borderRadius: '$1',
    textTransform: 'capitalize',

    '&[data-highlighted]': {
        backgroundColor: '$navy',
        '& svg path': {
            fill: '$white'
        }
    }
})

export const SelectItemText = SelectPrimitive.ItemText
