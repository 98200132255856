import useMergedRef from '@react-hook/merged-ref'
import classNames from 'classnames'
import {
    ComponentProps,
    ElementRef,
    forwardRef,
    useEffect,
    useRef,
    useState
} from 'react'
import { CSS, styled } from '../../theme/stitches.config'
import { Text } from '../Text'

const DEFAULT_TAG = 'input'

type InputProps = {
    id: string
    type: string
    label?: string
    error?: boolean
    errorMsg?: string
    animatedLabel?: boolean
    maxDate?: string
    handleOnInputClick?: () => void
} & { css?: CSS; as?: any; containerCss?: CSS } & ComponentProps<
        typeof DEFAULT_TAG
    >

export const Input = forwardRef<ElementRef<typeof DEFAULT_TAG>, InputProps>(
    (props, forwardRef) => {
        const {
            id,
            label,
            type,
            error,
            errorMsg,
            disabled,
            containerCss,
            className,
            css,
            animatedLabel,
            maxDate,
            handleOnInputClick,
            ...inputProps
        } = props

        const innerRef = useRef<HTMLInputElement | null>(null)
        const multiRef = useMergedRef(forwardRef, innerRef)
        const [labelAnimated, setLabelAnimated] = useState(false)

        const handleInputClick = () => {
            handleOnInputClick && handleOnInputClick()

            if (!labelAnimated) {
                setLabelAnimated(true)
            }
        }

        const handleOnFocus = () => {
            if (!labelAnimated) {
                setLabelAnimated(true)
            }
        }

        useEffect(() => {
            if ((innerRef.current && innerRef.current.value) || animatedLabel) {
                if (disabled) {
                    setLabelAnimated(true)
                }

                setLabelAnimated(true)
            }
        }, [disabled, setLabelAnimated, animatedLabel])

        return (
            <InputContainer
                css={containerCss}
                className={classNames({
                    dateInputContainer: type.includes('date')
                })}
            >
                <InputLabel
                    htmlFor={id}
                    className={classNames({
                        animated: labelAnimated,
                        disabled: disabled
                    })}
                >
                    {label}
                </InputLabel>
                <InputStyle
                    id={id}
                    css={css}
                    className={`${classNames({
                        error: error
                    })} ${className ? className : ''}`}
                    ref={multiRef}
                    type={type}
                    disabled={disabled}
                    onClick={handleInputClick}
                    onFocus={handleOnFocus}
                    onBlur={handleOnFocus}
                    max={maxDate}
                    {...inputProps}
                />
                {errorMsg ? (
                    <Text size={1} color="error" textTransform="upper">
                        {errorMsg}
                    </Text>
                ) : null}
            </InputContainer>
        )
    }
)

const InputContainer = styled('div', {
    width: '100%',
    height: '68rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '&.dateInputContainer input': {
        position: 'relative'
    }
})

const InputLabel = styled('label', {
    color: '$cyan',
    fontSize: '$3',
    position: 'relative',
    top: '25rem',
    left: '12rem',
    cursor: 'text',
    transition: '.5s ease',

    '&.animated': {
        fontSize: '$2',
        top: '0',
        left: '0'
    }
})

const InputStyle = styled('input', {
    width: '100%',
    padding: '10rem',
    border: '1rem solid $kashmir',
    background: '$dark_lighter',
    borderRadius: '$1',
    fontSize: '$3',
    color: '$white',

    '&:focus, &:focus-visible': {
        outline: 'none',
        borderColor: '$cyan'
    },

    '&.error': {
        borderColor: '$orange'
    },

    '&[disabled]': {
        color: '$nobel',
        background: '$tuna',
        pointerEvents: 'none',
        borderColor: '$tuna'
    },

    '&::placeholder': {
        color: '$cyan'
    },

    '&::-webkit-calendar-picker-indicator': {
        position: 'absolute',
        right: '0rem',
        backgroundColor: '$boston',
        cursor: 'pointer',
        padding: '12rem',
        borderRadius: '0 $1 $1 0 '
    }
})

Input.displayName = 'Input'
