import * as RadioGroupPrimitive from '@radix-ui/react-radio-group'
import { styled } from '../../theme/stitches.config'

export const RadioGroupItem = styled(RadioGroupPrimitive.Item, {
    p: '10rem ',
    fontSize: '$3',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',

    '& svg': {
        width: '$$svgWidth',
        mr: '10rem'
    },

    '&[data-state="checked"]': {
        '& svg': {
            '.radio-item-button_svg__rect': { fill: '#5878A5' },
            '.radio-item-button_svg__circle': { fill: '#fff' }
        }
    },

    '&[data-highlighted]': {
        backgroundColor: '$coarse',
        outline: 'none'
    }
})

export const RadioGroup = RadioGroupPrimitive.Root
