import { SVGProps } from 'react'

export const ArrowRight = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 6 8"
        {...props}
    >
        <path
            d="M1 6.829 3.828 4 1 1.172"
            stroke="#3F94B1"
            strokeWidth={2}
            strokeLinecap="round"
        />
    </svg>
)
