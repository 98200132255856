import { keyframes } from '@stitches/react'
import { ComponentProps, ElementRef, forwardRef } from 'react'
import { CSS, styled } from '../../theme/stitches.config'

const ldsEllipsis1 = keyframes({
    from: { transform: 'scale(0)' },
    to: { transform: 'scale(1)' }
})

const ldsEllipsis2 = keyframes({
    from: { transform: 'translate(0, 0)' },
    to: { transform: 'translate(19rem, 0)' }
})

const ldsEllipsis3 = keyframes({
    from: { transform: 'scale(1)' },
    to: { transform: 'scale(0)' }
})

const DEFAULT_TAG = 'div'

type EllipsisLoaderProps = ComponentProps<typeof DEFAULT_TAG> & {
    css?: CSS
    as?: any
}

export const EllipsisLoader = forwardRef<
    ElementRef<typeof DEFAULT_TAG>,
    EllipsisLoaderProps
>(({ ...props }, forwardRef) => (
    <StyledEllipsisLoader ref={forwardRef} {...props}>
        <div className="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </StyledEllipsisLoader>
))

const StyledEllipsisLoader = styled('div', {
    $$bgColor: '$colors$white',

    '.lds-ellipsis': {
        display: 'inline-block',
        position: 'relative',
        width: '64rem',
        height: '64rem'
    },
    '.lds-ellipsis div': {
        position: 'absolute',
        top: '27rem',
        width: '11rem',
        height: '11rem',
        borderRadius: '50%',
        background: '$$bgColor',
        animationTimingFunction: 'cubic-bezier(0, 1, 1, 0)'
    },
    '.lds-ellipsis div:nth-of-type(1)': {
        left: '6rem',
        animation: `${ldsEllipsis1} 0.6s infinite`
    },
    '.lds-ellipsis div:nth-of-type(2)': {
        left: '6rem',
        animation: `${ldsEllipsis2} 0.6s infinite`
    },
    '.lds-ellipsis div:nth-of-type(3)': {
        left: '26rem',
        animation: `${ldsEllipsis2} 0.6s infinite`
    },
    '.lds-ellipsis div:nth-of-type(4)': {
        left: '45rem',
        animation: `${ldsEllipsis3} 0.6s infinite`
    }
})

EllipsisLoader.displayName = 'EllipsisLoader'
