import MuxPlayer from '@mux/mux-player-react'
import { MuxAudioPlayerWrapper, MuxVideoPlayerWrapper } from 'ui'
import { CSS } from '../../theme/stitches.config'

type MuxPlayerComponentProps = {
    type?: 'audio' | 'video'
    className?: string
    muxPlaybackId: string
    muxTokens: { playback?: string; storyboard?: string; thumbnail?: string }
    metadata?: { [key: string]: any }
    primaryColor?: string
    secondaryColor?: string
    accentColor?: string
    autoPlay?: boolean
    onTimeUpdate?: () => void
    loop?: boolean
    css?: CSS
}

export const MuxPlayerComponent = ({
    type = 'video',
    className = 'session-mux-player',
    muxPlaybackId,
    muxTokens,
    metadata,
    primaryColor = '#ffffff',
    secondaryColor = '#2A3647',
    accentColor = '#63B9D7',
    autoPlay = false,
    onTimeUpdate,
    loop = false,
    css
}: MuxPlayerComponentProps) => {
    const PlayerWrapper =
        type === 'video' ? MuxVideoPlayerWrapper : MuxAudioPlayerWrapper
    const playerProps =
        type === 'audio'
            ? { audio: true, primaryColor, secondaryColor }
            : { accentColor }

    return (
        <PlayerWrapper css={css}>
            <MuxPlayer
                className={className}
                playbackId={muxPlaybackId}
                {...(metadata && { metadata })}
                autoPlay={autoPlay}
                onTimeUpdate={onTimeUpdate}
                loop={loop}
                tokens={muxTokens}
                style={{ aspectRatio: '16/9' }}
                {...playerProps}
            />
        </PlayerWrapper>
    )
}
