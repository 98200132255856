import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu'
import { keyframes } from '@stitches/react'
import { ReactNode } from 'react'
import { styled } from '../../theme/stitches.config'

const slideUpAndFade = keyframes({
    '0%': { opacity: 0, transform: 'translateY(2rem)' },
    '100%': { opacity: 1, transform: 'translateY(0)' }
})

const slideRightAndFade = keyframes({
    '0%': { opacity: 0, transform: 'translateX(-2px)' },
    '100%': { opacity: 1, transform: 'translateX(0)' }
})

const slideDownAndFade = keyframes({
    '0%': { opacity: 0, transform: 'translateY(-2px)' },
    '100%': { opacity: 1, transform: 'translateY(0)' }
})

const slideLeftAndFade = keyframes({
    '0%': { opacity: 0, transform: 'translateX(2px)' },
    '100%': { opacity: 1, transform: 'translateX(0)' }
})

const StyledContent = styled(DropdownMenuPrimitive.Content, {
    minWidth: '250rem',
    display: 'flex',
    zIndex: '99',
    flexDirection: 'column',
    backgroundColor: '$dark_lighter',
    filter: 'drop-shadow(0rem 4rem 4rem rgba(0, 0, 0, 0.25))',
    '@media (prefers-reduced-motion: no-preference)': {
        animationDuration: '400ms',
        animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
        willChange: 'transform, opacity',
        '&[data-state="open"]': {
            '&[data-side="top"]': { animationName: slideDownAndFade },
            '&[data-side="right"]': { animationName: slideLeftAndFade },
            '&[data-side="bottom"]': { animationName: slideUpAndFade },
            '&[data-side="left"]': { animationName: slideRightAndFade }
        }
    }
})

type DropdownMenuContentProps = {
    children: ReactNode
} & DropdownMenuPrimitive.DropdownMenuContentProps &
    React.RefAttributes<HTMLDivElement>

export const DropdownMenuContent = ({
    children,
    ...props
}: DropdownMenuContentProps) => {
    return (
        <DropdownMenuPrimitive.Portal>
            <StyledContent {...props}>{children}</StyledContent>
        </DropdownMenuPrimitive.Portal>
    )
}

export const DropdownMenuLabel = styled(DropdownMenuPrimitive.Label, {
    p: '10rem 20rem',
    fontSize: '$2',
    color: '$sky',
    textTransform: 'uppercase',
    fontWeight: '$normal'
})

export const DropdownMenuItem = styled(DropdownMenuPrimitive.Item, {
    $$svgWidth: '20rem',
    width: '100%',
    p: '10rem 20rem',
    fontSize: '$3',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    textTransform: 'uppercase',
    letterSpacing: '$1',
    fontWeight: '$bold',

    '&[data-highlighted]': {
        backgroundColor: '$coarse',
        color: '$navy',
        outline: 'none'
    },

    '&[data-disabled]': {
        background: '$dim',
        color: '$nobel'
    },

    variants: {
        withIcon: {
            true: {
                fontWeight: '$light',

                '& > svg': {
                    width: '$$svgWidth',
                    mr: '$2'
                }
            }
        }
    }
})

export const DropdownMenuRadioItem = styled(DropdownMenuPrimitive.RadioItem, {
    $$svgWidth: '30rem',
    p: '10rem 20rem',
    fontSize: '$3',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',

    '& svg': {
        width: '$$svgWidth',
        mr: '10rem'
    },

    '&[data-state="checked"]': {
        backgroundColor: '$coarse',
        '& svg': {
            '.radio-item-button_svg__rect': { fill: '#5878A5' },
            '.radio-item-button_svg__circle': { fill: '#fff' }
        }
    },

    '&[data-highlighted]': {
        backgroundColor: '$coarse',
        color: '$navy',
        outline: 'none'
    },

    variants: {
        textTransform: {
            upper: {
                textTransform: 'uppercase'
            },
            capitalize: {
                textTransform: 'capitalize'
            }
        }
    }
})
export const DropdownMenuRadioGroup = DropdownMenuPrimitive.RadioGroup
export const DropdownMenu = DropdownMenuPrimitive.Root
export const DropdownMenuTrigger = DropdownMenuPrimitive.Trigger
