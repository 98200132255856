import { styled } from '../../theme/stitches.config'

export const Box = styled('div', {
    $$customP: '0',
    width: '100%',
    boxSizing: 'border-box',
    display: 'flex',

    variants: {
        flex: {
            1: {
                flex: '1'
            },
            0: {
                flex: '0'
            }
        },
        direction: {
            row: {
                flexDirection: 'row'
            },
            col: {
                flexDirection: 'column'
            }
        },
        flexBasis: {
            auto: {
                flexBasis: 'auto'
            },
            maxContent: {
                flexBasis: 'max-content'
            },
            minContent: {
                flexBasis: 'min-content'
            },
            content: {
                flexBasis: 'content'
            }
        },
        alignItems: {
            center: {
                alignItems: 'center'
            },
            flexStart: {
                alignItems: 'flex-start'
            },
            flexEnd: {
                alignItems: 'flex-end'
            },
            stretch: {
                alignItems: 'stretch'
            }
        },
        size: {
            sm: {
                maxWidth: '$sm'
            },
            md: {
                maxWidth: '$md'
            },
            lg: {
                maxWidth: '$lg'
            }
        },
        justifyContent: {
            flexStart: {
                justifyContent: 'flex-start'
            },
            flexEnd: {
                justifyContent: 'flex-end'
            },
            center: {
                justifyContent: 'center'
            },
            spaceBetween: {
                justifyContent: 'space-between'
            },
            evenly: {
                justifyContent: 'space-evenly'
            },
            around: {
                justifyContent: 'space-around'
            }
        },
        padding: {
            lg: {
                p: '$7'
            },
            md: {
                p: '$4'
            },
            sm: {
                p: '$3'
            },
            custom: {
                p: '$$customP'
            },
            subscription: {
                p: '$4 $3'
            }
        },
        p: {
            1: {
                p: '$1'
            },
            2: {
                p: '$2'
            },
            3: {
                p: '$3'
            },
            4: {
                p: '$4'
            },
            5: {
                p: '$5'
            },
            6: {
                p: '$6'
            },
            7: {
                p: '$7'
            },
            8: {
                p: '$8'
            },
            9: {
                p: '$9'
            }
        },
        bg: {
            cloud_night: {
                background: '$cloudy_night'
            },
            dark_lighter: {
                background: '$dark_lighter'
            },
            dark: {
                background: '$dark'
            },
            cove: {
                background: '$cove'
            },
            gray: {
                background: '$gray'
            },
            light_gray: {
                background: '$light_gray'
            },
            black_pearl: {
                background: '$black_pearl'
            },
            squid: {
                background: '$squid'
            },
            subscription: {
                background:
                    'linear-gradient(180deg, #7E8CA4 -144.38%, #0F3443 189.38%)'
            }
        },
        bRadius: {
            '1': {
                borderRadius: '$1'
            },
            '2': {
                borderRadius: '$2'
            },
            '3': {
                borderRadius: '$3'
            },
            round: {
                borderRadius: '$round'
            }
        },
        dashBorder: {
            true: {
                border: '1rem dashed $kashmir'
            }
        },
        centered: {
            true: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }
        },
        relative: {
            true: {
                position: 'relative'
            }
        },
        boxShadowed: {
            true: {
                boxShadow: '0rem 4rem 4rem 0rem #00000040'
            }
        },
        pointer: {
            true: {
                cursor: 'pointer'
            }
        },
        gap: {
            1: {
                gap: '$1'
            },
            2: {
                gap: '$2'
            },
            3: {
                gap: '$3'
            },
            4: {
                gap: '$4'
            },
            5: {
                gap: '$5'
            },
            6: {
                gap: '$6'
            },
            7: {
                gap: '$7'
            },
            8: {
                gap: '$8'
            },
            9: {
                gap: '$9'
            }
        },
        overFlowXAuto: {
            true: {
                maxWidth: '100%',
                overflowX: 'auto'
            }
        }
    },
    defaultVariants: {
        direction: 'col'
    }
})
