import { styled } from '../../theme/stitches.config'

export const Container = styled('div', {
    width: '100%',
    boxSizing: 'border-box',
    ml: 'auto',
    mr: 'auto',

    variants: {
        size: {
            sm: {
                maxWidth: '$sm'
            },
            md: {
                maxWidth: '$md'
            },
            lg: {
                maxWidth: '$lg'
            },
            xl: {
                maxWidth: '$xl'
            },
            hq: {
                maxWidth: '$hq'
            },
            none: {
                maxWidth: 'none'
            }
        },
        spaceBetween: {
            true: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
            }
        },
        alignStart: {
            true: {
                alignItems: 'flex-start'
            }
        },
        centered: {
            true: {
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }
        }
    },
    defaultVariants: {
        size: 'none'
    }
})
