import { yupResolver } from '@hookform/resolvers/yup'
import { useAuth } from 'auth'
import { useRouter } from 'next/router'
import { Fragment, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import {
    Box,
    Button,
    Divider,
    Form,
    HStack,
    Heading,
    Input,
    LinkButton,
    TwoFactor,
    VStack
} from 'ui'
import fetcher from 'ui/utils/fetcher'
import * as yup from 'yup'
import { MessageDialog, MessageDialogType } from '../MessageDialog'
import { LoaderIcon } from '../Svgs'
import ChangePasswordDialog, { ChangePwFormData } from './ChangePassword'

type FormData = {
    first_name: string
    last_name: string
    email: string
}

const schema = yup
    .object({
        first_name: yup.string().required('Required'),
        last_name: yup.string().required('Required')
    })
    .required()

type Props = {
    logoutHandler(): void
}

export const AccountSetting = ({ logoutHandler }: Props) => {
    const { user } = useAuth()
    const router = useRouter()

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue
    } = useForm<FormData>({
        resolver: yupResolver(schema)
    })

    useEffect(() => {
        if (user) {
            setValue('first_name', user.data.first_name)
            setValue('last_name', user.data.last_name)
            setValue('email', user.data.email)
        }
    }, [user])

    const [isChangePwDialogOpen, setIsChangePwDialogOpen] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [messageDialog, setMessageDialog] =
        useState<MessageDialogType>(undefined)

    const handleCloseMessageDialog = () => {
        setMessageDialog(undefined)
    }

    const handleOpenDialog = () => {
        setIsChangePwDialogOpen(true)
    }

    const handleCloseDialog = () => {
        setIsChangePwDialogOpen(false)
    }

    const onSubmit = handleSubmit(async (data) => {
        setIsSubmitting(true)

        try {
            await fetcher.put(`/users/${user.data.id}`, { ...data })

            setMessageDialog({
                title: 'Success',
                description: 'We have successfully updated your information.',
                onClick: () => router.back()
            })
            setIsSubmitting(false)
        } catch (error: any) {
            if (error.isAxiosError) {
                setMessageDialog({
                    title: `Error`,
                    description: error.response.data.message
                })
            } else {
                alert(error)
            }
            setIsSubmitting(false)
        }
    })

    const handleChangePassword = async (data: ChangePwFormData) => {
        setIsSubmitting(true)

        try {
            await fetcher.put(`/users/${user.data.id}/password`, {
                current_password: data.currentPassword,
                new_password: data.newPassword,
                new_password_confirmation: data.confirmNewPassword
            })

            setMessageDialog({
                title: 'Success',
                description: 'We have successfully updated your password.'
            })
            logoutHandler()
            handleCloseDialog()
            setIsSubmitting(false)
        } catch (error: any) {
            if (error.isAxiosError) {
                setMessageDialog({
                    title: `Error`,
                    description: error.response.data.message
                })
            } else {
                alert(error)
            }
            setIsSubmitting(false)
        }
    }

    return (
        <Box gap={'3'} padding="lg">
            <Form onSubmit={onSubmit}>
                <VStack alignItems="flexStart">
                    <Heading
                        css={{
                            mb: '$4'
                        }}
                    >
                        Account Settings
                    </Heading>

                    <HStack spacing="5">
                        <Input
                            id="first-name"
                            type="text"
                            label="First Name"
                            error={errors.first_name ? true : false}
                            errorMsg={errors.first_name?.message}
                            animatedLabel
                            {...register('first_name')}
                        />
                        <Input
                            id="last-name"
                            type="text"
                            label="Last Name"
                            error={errors.last_name ? true : false}
                            errorMsg={errors.last_name?.message}
                            {...register('last_name')}
                            animatedLabel
                        />
                    </HStack>

                    <Input
                        id="email"
                        type="text"
                        label="Email"
                        {...register('email')}
                        animatedLabel
                    />

                    <Button
                        type="submit"
                        color={isSubmitting ? 'loading' : 'primary'}
                        rotateSvg={isSubmitting}
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? (
                            <Fragment>
                                <LoaderIcon /> saving...
                            </Fragment>
                        ) : (
                            'save'
                        )}
                    </Button>

                    <LinkButton
                        type="button"
                        size="sm"
                        onClick={handleOpenDialog}
                    >
                        change password
                    </LinkButton>
                </VStack>
            </Form>

            <Divider />

            {user && (
                <Box>
                    <TwoFactor
                        twoFactorEnabled={user?.data.two_factor_auth_enabled}
                    />
                </Box>
            )}

            <ChangePasswordDialog
                isOpen={isChangePwDialogOpen}
                isSubmitting={isSubmitting}
                handleCloseDialog={handleCloseDialog}
                handleChangePassword={handleChangePassword}
            />

            <MessageDialog
                messageDialog={messageDialog}
                handleCloseDialog={handleCloseMessageDialog}
            />
        </Box>
    )
}
