import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu'
import { keyframes } from '@stitches/react'
import classNames from 'classnames'
import {
    ChangeEvent,
    Dispatch,
    KeyboardEvent,
    SetStateAction,
    useEffect,
    useRef,
    useState
} from 'react'
import { useMediaQuery } from 'react-responsive'
import ArrowLeft from 'share-svgs/svgs/arrow-left.svg'
import RadioItemButtonIcon from 'share-svgs/svgs/radio-item-button.svg'
import SearchOptionsIcon from 'share-svgs/svgs/search-option-icon.svg'
import { CSS, styled } from '../../theme/stitches.config'
import { IconButton } from '../Button'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuLabel,
    DropdownMenuRadioGroup,
    DropdownMenuTrigger
} from '../DropDownMenu'
import { Flex, HStack, VStack } from '../Layout'
import { LinkButton } from '../Link'
import { Search } from '../Svgs'
import { Text } from '../Text'
import { useCurrentSearchPreference } from './current-search-preference'

const DEFAULT_TAG = 'input'

type SearchInputProps = {
    containerCss?: CSS
    wrapperCss?: CSS
    autoFocus?: boolean
    defaultValue?: string
    testId?: string
    placeholder?: string
    noDropdown?: boolean
    openAnimated?: boolean
    displayResult?: {
        count: number
        query: string
    }
    searchInputIsActiveOnMobile?: boolean
    onSearch: (query: string) => void
    onClearSearchValue?: () => void
    setSearchInputIsActiveOnMobile?: Dispatch<SetStateAction<boolean>>
} & { css?: CSS; as?: any } & React.ComponentProps<typeof DEFAULT_TAG>

export const SearchInput = ({
    testId,
    containerCss,
    wrapperCss,
    displayResult,
    defaultValue = '',
    placeholder,
    noDropdown,
    autoFocus = true,
    openAnimated,
    searchInputIsActiveOnMobile,
    onSearch,
    onClearSearchValue,
    setSearchInputIsActiveOnMobile,
    ...props
}: SearchInputProps) => {
    const search = useRef<HTMLInputElement | null>(null)
    const isDesktop = useMediaQuery({ query: '(min-width: 768px)' })

    const [hasAnimated, setHasAnimated] = useState(
        () => defaultValue || openAnimated
    )
    const [isError, setIsError] = useState(false)
    const [errorShake, setErrorShake] = useState(false)
    const [searchValue, setSearchValue] = useState(() => defaultValue)

    const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (isError && event.target.value.trim().length > 0) {
            setIsError(false)
        }

        setSearchValue(event.target.value)
    }

    const handleEmptyString = () => {
        setIsError(true)
        setErrorShake(true)
        setTimeout(() => {
            setErrorShake(false)
        }, 1000)
    }

    const handleOnKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.code === 'Enter' && search && search.current) {
            if (search.current.value.trim() === '') {
                handleEmptyString()
                return
            }
            onSearch(search.current.value)
        }
    }

    const handleIconClick = () => {
        setSearchInputIsActiveOnMobile && setSearchInputIsActiveOnMobile(true)

        if (!hasAnimated) {
            setHasAnimated(true)
        }

        if (hasAnimated && search && search.current) {
            if (search.current.value.trim() === '') {
                handleEmptyString()
                return
            }

            onSearch(search.current.value)
        }
    }

    const handleClearValue = () => {
        onClearSearchValue && onClearSearchValue()
        setSearchValue('')
    }

    const handleCloseSearch = () => {
        setSearchInputIsActiveOnMobile && setSearchInputIsActiveOnMobile(false)

        setHasAnimated(false)
    }
    useEffect(() => {
        setSearchValue(defaultValue || '')
    }, [defaultValue])

    if (displayResult) {
        return (
            <VStack spacing={'1'} widthAuto>
                <SearchInputWrapper css={wrapperCss}>
                    <SearchInputContainer
                        css={containerCss}
                        className={classNames({
                            animated: hasAnimated,
                            error: isError,
                            'error-shake': errorShake
                        })}
                    >
                        {hasAnimated ? (
                            <HStack spacing={'2'}>
                                {noDropdown ? null : <SearchDropdown />}
                                <BareInput
                                    autoFocus={autoFocus}
                                    id="search-input"
                                    type="text"
                                    placeholder={placeholder}
                                    ref={search}
                                    data-testid={testId ? testId : 'search-box'}
                                    value={searchValue}
                                    onChange={handleOnChange}
                                    onKeyDown={handleOnKeyDown}
                                    {...props}
                                />
                            </HStack>
                        ) : null}
                    </SearchInputContainer>

                    <IconButton
                        aria-label={'search'}
                        primaryColor={'sky'}
                        css={{
                            $$iconHeight: '20rem',
                            position: 'absolute',
                            right: '10rem',
                            top: '50%',
                            transform: 'translateY(-50%)'
                        }}
                        onClick={handleIconClick}
                    >
                        <Search />
                    </IconButton>
                </SearchInputWrapper>
                <Flex justifyContent={'spaceBetween'}>
                    <Text
                        as="p"
                        color={'cove'}
                        css={{
                            mr: '$1'
                        }}
                    >
                        {displayResult.count} results for &quot;
                        <Text color={'cove'} weight={'bold'} inline>
                            {displayResult.query}
                        </Text>
                        &quot;
                    </Text>
                    <LinkButton size={'sm'} onClick={handleClearValue}>
                        clear
                    </LinkButton>
                </Flex>
            </VStack>
        )
    }

    return (
        <Flex gap={'4'} direction={'row'} justifyContent={'flexEnd'}>
            {searchInputIsActiveOnMobile && !isDesktop && (
                <ArrowLeft
                    onClick={handleCloseSearch}
                    width={20}
                    minWidth={20}
                    cursor={'pointer'}
                />
            )}
            <SearchInputWrapper
                css={wrapperCss}
                className={classNames({
                    mobibleSearchActive: searchInputIsActiveOnMobile
                })}
            >
                <SearchInputContainer
                    css={containerCss}
                    className={classNames({
                        animated: hasAnimated,
                        error: isError,
                        'error-shake': errorShake
                    })}
                >
                    {hasAnimated ? (
                        <HStack spacing={'2'}>
                            {noDropdown ? null : <SearchDropdown />}
                            <BareInput
                                autoFocus={autoFocus}
                                id="search-input"
                                type="text"
                                placeholder={placeholder}
                                ref={search}
                                data-testid={testId ? testId : 'search-box'}
                                value={searchValue}
                                onChange={handleOnChange}
                                onKeyDown={handleOnKeyDown}
                                {...props}
                            />
                        </HStack>
                    ) : null}
                </SearchInputContainer>
                <IconButton
                    primaryColor={'sky'}
                    aria-label={'search'}
                    css={{
                        $$iconHeight: '20rem',
                        position: 'absolute',
                        right: '10rem',
                        top: '50%',
                        transform: 'translateY(-50%)'
                    }}
                    onClick={handleIconClick}
                >
                    <Search />
                </IconButton>
            </SearchInputWrapper>
        </Flex>
    )
}

const SearchInputWrapper = styled('div', {
    '&.mobibleSearchActive': {
        width: '100%',
        minWidth: '230rem',
        '@md': {
            width: 'auto'
        }
    },
    position: 'relative'
})

const shakeKeyFrame = keyframes({
    '0%,100%': {
        transform: 'translateX(0)'
    },
    '25%,75%': {
        transform: 'translateX(-5px)'
    },
    '50%': {
        transform: 'translateX(5px)'
    }
})

const SearchInputContainer = styled('div', {
    p: '8rem 12rem',
    border: '1rem solid $cove',
    borderRadius: '$1',
    display: 'flex',
    background: '$dark_lighter',
    position: 'relative',
    left: '20rem',
    opacity: '0',
    transition: '.5s ease',

    '&.animated': {
        left: 0,
        opacity: '1'
    },

    '&.error': {
        borderColor: '$orange'
    },
    '&.error-shake': {
        animation: `${shakeKeyFrame} .5s ease-in-out infinite`
    }
})

const BareInput = styled('input', {
    width: '230rem',
    padding: '0',
    border: 'none',
    background: '$dark_lighter',
    fontSize: '$3',
    p: '5rem',
    display: 'flex',
    flex: '1',
    color: '$white',
    outline: 'none',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    position: 'relative',
    left: '-10rem'
})

const SearchDropdown = () => {
    const { setSearchPreference } = useCurrentSearchPreference()
    const { searchPreference } =
        useCurrentSearchPreference() ||
        JSON.parse(localStorage.searchPreference)

    const handleSetSearchPreference = (value: string) => {
        const searchPreference = value as 'partial' | 'exact'
        setSearchPreference(searchPreference)
    }

    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <IconButton
                    aria-label={'search options'}
                    css={{
                        mr: '5rem',
                        borderRight: '2rem solid $cyan',
                        paddingRight: '10rem',
                        '& svg': {
                            height: '20rem'
                        }
                    }}
                >
                    <SearchOptionsIcon />
                </IconButton>
            </DropdownMenuTrigger>

            <DropdownMenuContent
                align="start"
                alignOffset={-8}
                sideOffset={12}
                style={{
                    backgroundColor: '#19202A',
                    minWidth: '150rem',
                    borderBottomLeftRadius: '10rem',
                    borderBottomRightRadius: '10rem'
                }}
            >
                <DropdownMenuLabel
                    css={{ fontSize: '$2', color: '$white', p: '10rem' }}
                >
                    Search Option
                </DropdownMenuLabel>
                <DropdownMenuRadioGroup
                    value={searchPreference}
                    onValueChange={handleSetSearchPreference}
                >
                    <SearchDropdownMenuRadioItem value={'partial'}>
                        <RadioItemButtonIcon /> Partial Match
                    </SearchDropdownMenuRadioItem>
                    <SearchDropdownMenuRadioItem value={'exact'}>
                        <RadioItemButtonIcon /> Exact Match
                    </SearchDropdownMenuRadioItem>
                </DropdownMenuRadioGroup>
            </DropdownMenuContent>
        </DropdownMenu>
    )
}
const SearchDropdownMenuRadioItem = styled(DropdownMenuPrimitive.RadioItem, {
    p: '10rem ',
    fontSize: '$3',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',

    '& svg': {
        width: '$$svgWidth',
        mr: '10rem'
    },

    '&[data-state="checked"]': {
        '& svg': {
            '.radio-item-button_svg__rect': { fill: '#5878A5' },
            '.radio-item-button_svg__circle': { fill: '#fff' }
        }
    },

    '&[data-highlighted]': {
        backgroundColor: '$coarse',
        color: '$cyan',
        outline: 'none'
    }
})
