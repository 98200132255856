import MuxPlayer from '@mux/mux-player-react'
import '@mux/mux-player/themes/minimal'
import { useState } from 'react'
import { isMobile } from 'react-device-detect'
import Mute from 'share-svgs/svgs/muted.svg'
import Unmute from 'share-svgs/svgs/unmuted.svg'
import { Box, Flex, formatAtUTCTimeZone, IconButton, Skeletons, Text } from 'ui'
import { styled } from 'ui/theme/stitches.config'
import { Thumbnails } from 'ui/types'
import { convertSecondsToHHMMSS } from 'ui/utils/timeHelper'

type Props = {
    id: string
    type: 'audio' | 'video'
    name: string
    muxPlaybackId: string
    muxTokens: { playback: string; storyboard: string; thumbnail: string }
    duration: number
    recordedAt?: string
    thumbnails?: Thumbnails
}

const SessionCardPlayer = ({
    id,
    name,
    muxPlaybackId,
    muxTokens,
    duration,
    recordedAt,
    type,
    thumbnails
}: Props) => {
    const [posterImage, setPosterImage] = useState(
        thumbnails ? thumbnails['jpg-desktop'] : '/placeholder.webp'
    )
    const [paused, setPlayPaused] = useState(true)
    const [muted, setMuted] = useState(true)

    const [posterImageLoad, setPosterImageLoad] = useState(false)

    if (isMobile) {
        return (
            <Box
                css={{
                    height: '200rem',
                    width: '100%',
                    position: 'relative'
                }}
            >
                {!posterImageLoad && <Skeletons amount={1} height="200rem" />}

                <StyledSession
                    src={type === 'video' ? posterImage : '/audio-wave.webp'}
                    onError={() => setPosterImage('/placeholder.webp')}
                    alt={name}
                    width={500}
                    height={200}
                    onLoad={() => setPosterImageLoad(true)}
                />
            </Box>
        )
    }

    return (
        <Box
            onMouseEnter={() => setPlayPaused(false)}
            onMouseLeave={() => setPlayPaused(true)}
            css={{
                width: '100%',
                height: '200rem',
                position: 'relative',
                '& .hidden': {
                    display: 'none'
                },
                '& .visible': {
                    display: 'flex'
                }
            }}
        >
            <Box
                css={{
                    height: '100%',
                    width: '100%'
                }}
                className={posterImageLoad ? 'visible' : 'hidden'}
            >
                {!posterImageLoad && <Skeletons amount={1} height="200rem" />}

                <StyledSession
                    src={type === 'video' ? posterImage : '/audio-wave.webp'}
                    onError={() => setPosterImage('/placeholder.webp')}
                    alt={name}
                    width={500}
                    height={200}
                    className={paused ? 'visible' : 'hidden'}
                    onLoad={() => setPosterImageLoad(true)}
                />
            </Box>

            {!paused && (
                <Flex
                    gap="2"
                    justifyContent={'flexEnd'}
                    css={{
                        position: 'absolute',
                        top: '5rem',
                        right: '5rem',
                        zIndex: '10'
                    }}
                >
                    <IconButton
                        primaryColor={'white'}
                        aria-label={'mute'}
                        onClick={(e) => {
                            e.stopPropagation()
                            setMuted((muted: boolean) => !muted)
                        }}
                        css={{
                            '& svg': {
                                padding: '5rem 0rem 0rem 5rem',
                                borderRadius: '5rem',
                                background: '$black',
                                opacity: '0.8'
                            }
                        }}
                    >
                        {muted ? <Mute /> : <Unmute />}
                    </IconButton>
                </Flex>
            )}

            {
                <MuxMiniPlayerWrapper className={paused ? 'hidden' : 'visible'}>
                    <MuxPlayer
                        // need theme minimal to remove center control
                        theme="minimal"
                        playbackId={muxPlaybackId}
                        tokens={muxTokens}
                        metadata={{
                            video_id: id,
                            video_title: name
                        }}
                        loop
                        audio={type === 'audio'}
                        default-show-remaining-time
                        muted={muted}
                        defaultShowRemainingTime={true}
                        paused={paused}
                        accent-color="#63B9D7"
                        style={
                            type === 'video'
                                ? {
                                      height: '200rem'
                                  }
                                : ({
                                      height: '200rem',
                                      '--controls': 'none'
                                  } as React.CSSProperties)
                        }
                    />
                </MuxMiniPlayerWrapper>
            }

            {recordedAt ? (
                <Text
                    size={'1'}
                    css={{
                        position: 'absolute',
                        left: '5rem',
                        top: '5rem',
                        padding: '0 5rem',
                        borderRadius: '5rem',
                        backgroundColor: '$black',
                        opacity: '0.8'
                    }}
                >
                    Recorded on {formatAtUTCTimeZone(recordedAt)}
                </Text>
            ) : null}

            <Text
                size="1"
                css={{
                    position: 'absolute',
                    right: '5rem',
                    bottom: '5rem',
                    padding: '0 5rem',
                    borderRadius: '5rem',
                    background: '$black',
                    opacity: '0.8'
                }}
                textAlign="end"
            >
                {convertSecondsToHHMMSS(duration)}
            </Text>
        </Box>
    )
}

const MuxMiniPlayerWrapper = styled('div', {
    'mux-player': {
        '--controls': 'none'
    }
})

const StyledSession = styled('img', {
    borderRadius: '6rem',
    width: '100%',
    height: '100%',
    objectFit: 'cover'
})

export default SessionCardPlayer
