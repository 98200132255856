import { SVGProps } from 'react'

export const Folder = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 18 18"
        {...props}
    >
        <title>folder icon</title>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.66 4.744H9.69V4.34c0-.84-.687-1.527-1.527-1.527h-4.95c-.839 0-1.526.688-1.526 1.527v9.071c0 .84.687 1.527 1.527 1.527H14.66c.84 0 1.528-.687 1.528-1.527v-7.14c0-.84-.688-1.527-1.528-1.527Z"
            fill="#7E8CA4"
        />
    </svg>
)
