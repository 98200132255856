import { SVGProps } from 'react'

export const CrossIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 8 8"
        {...props}
    >
        <title>cross icon</title>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m1.349.288-1.06 1.06L2.938 4 .29 6.652l1.06 1.06L4 5.061l2.652 2.651 1.06-1.06L5.063 4l2.65-2.652-1.06-1.06L4 2.939 1.35.288Z"
            fill="#fff"
        />
    </svg>
)
