import * as SwitchPrimitive from '@radix-ui/react-switch'
import { styled } from '../../theme/stitches.config'

export const Switch = styled(SwitchPrimitive.Root, {
    all: 'unset',
    width: 42,
    height: 22,
    backgroundColor: '$dark_lighter',
    borderRadius: '9999px',
    position: 'relative',
    border: '1rem solid $kashmir',
    cursor: 'pointer',
    WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
    '&:focus': { boxShadow: `0 0 0 2px $kashmir` },
    '&[data-state="checked"]': {
        backgroundColor: '$navy'
    }
})

export const SwitchThumb = styled(SwitchPrimitive.Thumb, {
    display: 'block',
    width: 18,
    height: 18,
    backgroundColor: '$boston',
    borderRadius: '9999px',
    transition: 'transform 100ms',
    transform: 'translateX(3px)',
    willChange: 'transform',
    '&[data-state="checked"]': {
        backgroundColor: '$white',
        transform: 'translateX(22px)'
    }
})
