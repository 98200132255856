import * as AccordionPrimitive from '@radix-ui/react-accordion'
import { keyframes } from '@stitches/react'
import { ComponentProps, ElementRef, forwardRef } from 'react'
import { CSS, styled } from '../../theme/stitches.config'
import { Heading } from '../Heading'
import { ArrowUp } from '../Svgs'

const slideDown = keyframes({
    from: { height: 0 },
    to: { height: 'var(--radix-accordion-content-height)' }
})

const slideUp = keyframes({
    from: { height: 'var(--radix-accordion-content-height)' },
    to: { height: 0 }
})

const StyledTrigger = styled(AccordionPrimitive.Trigger, {
    backgroundColor: 'transparent',
    pb: '15rem',
    flex: 1,
    display: 'flex',
    alignItems: 'center'
})

const StyledArrowUpSvg = styled(ArrowUp, {
    height: '20rem',
    ml: 'auto',
    display: 'flex',
    flexShrink: '0',
    transform: 'rotate(180deg)',

    '[data-state="open"] &': {
        transform: 'none'
    }
})

const StyledHeader = styled(AccordionPrimitive.Header, {
    display: 'flex'
})

export const SearchAccordion = styled(AccordionPrimitive.Root, {
    width: '100%'
})
export const SearchAccordionItem = styled(AccordionPrimitive.Item, {
    overflow: 'hidden'
})

const TRIGGER_DEFAULT_TAG = 'button'
type AccordionTriggerProps = {
    title: string
} & ComponentProps<typeof TRIGGER_DEFAULT_TAG> & {
        css?: CSS
        as?: any
    }

export const SearchAccordionTrigger = forwardRef<
    ElementRef<typeof TRIGGER_DEFAULT_TAG>,
    AccordionTriggerProps
>(({ children, title, ...props }, forwardedRef) => (
    <StyledHeader>
        <StyledTrigger {...props} ref={forwardedRef}>
            <Heading>{title}</Heading> <StyledArrowUpSvg />
        </StyledTrigger>
    </StyledHeader>
))
SearchAccordionTrigger.displayName = 'SearchAccordionTrigger'

const CONTENT_DEFAULT_TAG = 'div'
type AccordionContentProps = ComponentProps<typeof CONTENT_DEFAULT_TAG> & {
    css?: CSS
    as?: any
}

const StyledContent = styled(AccordionPrimitive.Content, {
    overflow: 'hidden',

    '&[data-state="open"]': {
        animation: `${slideDown} 300ms cubic-bezier(0.87, 0, 0.13, 1)`
    },
    '&[data-state="closed"]': {
        animation: `${slideUp} 300ms cubic-bezier(0.87, 0, 0.13, 1)`
    }
})

export const SearchAccordionContent = forwardRef<
    ElementRef<typeof CONTENT_DEFAULT_TAG>,
    AccordionContentProps
>(({ children, ...props }, forwardedRef) => (
    <StyledContent {...props} ref={forwardedRef}>
        {children}
    </StyledContent>
))

SearchAccordionContent.displayName = 'SearchAccordionContent'
