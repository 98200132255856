import { motion } from 'framer-motion'
import { isEmpty } from 'lodash'
import { useMediaQuery } from 'react-responsive'
import CategorySearchIcon from 'share-svgs/svgs/category-search.svg'
import {
    FileButton,
    Flex,
    Grid,
    Heading,
    Pagination,
    Skeletons,
    Text,
    VStack
} from 'ui'
import { SearchCategory } from 'ui/types'

type Props = {
    searchData: {
        data: SearchCategory[]
        meta: {
            last_page: number
        }
    }
    page: number
    handleSetPage(page: { selected: number }): void
    handleRedirectCategoryFolder(category: SearchCategory): void
}

export const CategoriesTabContent = ({
    searchData,
    page,
    handleSetPage,
    handleRedirectCategoryFolder
}: Props) => {
    const isDesktop = useMediaQuery({ query: '(min-width: 768px)' })

    return (
        <VStack spacing="3" alignItems="flexStart">
            <Heading>Categories</Heading>

            {!searchData ? (
                <Grid
                    as={motion.div}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    columns={{ '@initial': 1, '@md': 4 }}
                    gap="3"
                >
                    <Skeletons amount={4} height="70rem" />
                </Grid>
            ) : null}

            {searchData &&
            searchData.data &&
            !isEmpty(searchData.data) &&
            searchData.data.length ? (
                <Grid
                    as={motion.div}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    columns={{ '@initial': 1, '@md': 4 }}
                    gap="3"
                >
                    {searchData.data.map((category: SearchCategory) => (
                        <FileButton
                            key={category.id}
                            svg={<CategorySearchIcon />}
                            title={category.name}
                            description={
                                isDesktop ? category.description : undefined
                            }
                            onClick={() =>
                                handleRedirectCategoryFolder(category)
                            }
                        />
                    ))}
                </Grid>
            ) : null}

            {searchData && searchData.data && isEmpty(searchData.data) ? (
                <Text weight="light">
                    There&apos;re no results for Categories
                </Text>
            ) : null}

            {searchData && searchData.meta && searchData.meta.last_page > 1 ? (
                <Flex css={{ mt: '$9' }}>
                    <Pagination
                        pageCount={searchData.meta.last_page}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={1}
                        forcePage={page - 1}
                        onPageChange={handleSetPage}
                    />
                </Flex>
            ) : null}
        </VStack>
    )
}
