import { ComponentProps, ElementRef, forwardRef, ReactElement } from 'react'
import { styled, CSS } from '../../theme/stitches.config'
import { VStack } from '../Layout'
import { Text } from '../Text'

const BoxButtonContainer = styled('button', {
    $$svgWidth: '65rem',
    p: '20rem',
    width: '100%',
    // border: '1rem dashed $kashmir',
    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='%23566785FF' stroke-width='1' stroke-dasharray='7' stroke-dashoffset='27' stroke-linecap='square'/%3e%3c/svg%3e")`,
    borderRadius: '$1',
    display: 'flex',

    '& > svg': {
        width: '$$svgWidth',
        mr: '$3',
        flexShrink: '0'
    },

    variants: {
        alignItems: {
            center: {
                alignItems: 'center'
            },
            flexStart: {
                alignItems: 'flex-start'
            },
            flexEnd: {
                alignItems: 'flex-end'
            }
        }
    },
    defaultVariants: {
        alignItems: 'center'
    }
})

const DEFAULT_TAG = 'button'
type BoxButtonProps = {
    title: string
    description: ReactElement
    svg: ReactElement
} & ComponentProps<typeof DEFAULT_TAG> & { css?: CSS; as?: any }

export const BoxButton = forwardRef<
    ElementRef<typeof DEFAULT_TAG>,
    BoxButtonProps
>(({ svg, title, description, css, ...props }, forwardRef) => {
    return (
        <BoxButtonContainer ref={forwardRef} type="button" css={css} {...props}>
            {svg}
            <VStack spacing="2" alignItems="flexStart">
                <Text weight="bold">{title}</Text>
                {description}
            </VStack>
        </BoxButtonContainer>
    )
})

BoxButton.displayName = 'BoxButton'
