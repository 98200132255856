import { SVGProps } from 'react'

export const VideoFolder = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 18 18"
        {...props}
    >
        <title>video folder icon</title>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.69 4.744h4.97c.84 0 1.528.688 1.528 1.527v7.139c0 .84-.688 1.527-1.528 1.527H3.214a1.53 1.53 0 0 1-1.526-1.527V4.34c0-.84.686-1.527 1.526-1.527h4.949c.84 0 1.527.687 1.527 1.526v.405Zm-1.838 6.963 3.203-1.85c.296-.17.296-.45 0-.622l-3.203-1.85c-.297-.17-.54-.03-.54.312v3.698c0 .343.243.483.54.312Z"
            fill="#7E8CA4"
        />
    </svg>
)
