import { motion } from 'framer-motion'
import { isEmpty } from 'lodash'
import { useMediaQuery } from 'react-responsive'
import SessionAudio from 'share-svgs/svgs/audio-session.svg'
import {
    FileButton,
    Flex,
    Grid,
    Heading,
    Pagination,
    Skeletons,
    Text,
    VStack
} from 'ui'
import { SearchSession } from 'ui/types'

type Props = {
    searchData: {
        data: SearchSession[]
        meta: {
            last_page: number
        }
    }
    page: number
    handleSetPage(page: { selected: number }): void
    handleRedirectSession(session: SearchSession): void
}

export const SessionsTabContent = ({
    searchData,
    page,
    handleSetPage,
    handleRedirectSession
}: Props) => {
    const isDesktop = useMediaQuery({ query: '(min-width: 768px)' })

    return (
        <VStack spacing="3" alignItems="flexStart">
            <Heading>Sessions</Heading>

            {!searchData ? (
                <Grid
                    as={motion.div}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    columns={{ '@initial': 1, '@md': 4 }}
                    gap="3"
                >
                    <Skeletons amount={4} height="70rem" />
                </Grid>
            ) : null}

            {searchData &&
            searchData.data &&
            !isEmpty(searchData.data) &&
            searchData.data.length ? (
                <Grid
                    as={motion.div}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    columns={{ '@initial': 1, '@md': 4 }}
                    gap="3"
                >
                    {searchData.data.map((session: SearchSession) =>
                        session.type === 'video' ? (
                            <FileButton
                                key={session.id}
                                imgSrc={
                                    session.thumbnails
                                        ? session.thumbnails['jpg-desktop']
                                        : '/audio-wave.webp'
                                }
                                alt={session.display_name}
                                title={session.display_name}
                                description={
                                    isDesktop
                                        ? session.snippet
                                        : session.category.name
                                }
                                onClick={() => handleRedirectSession(session)}
                            />
                        ) : (
                            <FileButton
                                key={session.id}
                                svg={<SessionAudio />}
                                title={session.display_name}
                                description={
                                    isDesktop
                                        ? session.snippet
                                        : session.category.name
                                }
                                onClick={() => handleRedirectSession(session)}
                            />
                        )
                    )}
                </Grid>
            ) : null}

            {searchData && searchData.data && isEmpty(searchData.data) ? (
                <Text weight="light">
                    There&apos;re no results for Sessions
                </Text>
            ) : null}

            {searchData && searchData.meta && searchData.meta.last_page > 1 ? (
                <Flex css={{ mt: '$9' }}>
                    <Pagination
                        pageCount={searchData.meta.last_page}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={1}
                        forcePage={page - 1}
                        onPageChange={handleSetPage}
                    />
                </Flex>
            ) : null}
        </VStack>
    )
}
