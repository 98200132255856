import { styled } from '../../theme/stitches.config'

export const MuxVideoPlayerWrapper = styled('div', {
    $$mdHeight: '402.19rem',
    '@md': {
        'mux-player': {
            height: '$$mdHeight'
        }
    },
    '& mux-player::part(controller)': {
        zIndex: 10
    }
})
