import { styled } from '../../theme/stitches.config'

export const Text = styled('span', {
    lineHeight: '20rem',
    margin: '0',
    fontVariantNumeric: 'tabular-nums',
    display: 'block',

    variants: {
        color: {
            white: {
                color: '$white'
            },
            boston: {
                color: '$boston'
            },
            cove: {
                color: '$cove'
            },
            gray: {
                color: '$gray'
            },
            kashmir: {
                color: '$kashmir'
            },
            navy: {
                color: '$navy'
            },
            sky: {
                color: '$sky'
            },
            cyan: {
                color: '$cyan'
            },
            orange: {
                color: '$orange'
            },
            dark: {
                color: '$dark'
            },
            dark_lighter: {
                color: '$dark_lighter'
            },
            bluish_grey: {
                color: '$bluish_grey'
            },
            yellow: {
                color: '$yellow'
            },
            error: {
                color: '$orange'
            }
        },
        textTransform: {
            upper: {
                textTransform: 'uppercase'
            },
            capitalize: {
                textTransform: 'capitalize'
            },
            initial: {
                textTransform: 'initial'
            }
        },
        textAlign: {
            left: {
                textAlign: 'left'
            },
            right: {
                textAlign: 'right'
            },
            center: {
                textAlign: 'center'
            },
            end: {
                textAlign: 'end'
            }
        },
        letterSpacing: {
            true: {
                letterSpacing: '$1'
            }
        },
        size: {
            '1': {
                fontSize: '$1'
            },
            '2': {
                fontSize: '$2'
            },
            '3': {
                fontSize: '$3'
            },
            '4': {
                fontSize: '$4'
            },
            '5': {
                fontSize: '$5'
            }
        },
        weight: {
            extraLight: {
                fontWeight: '$extraLight'
            },
            light: {
                fontWeight: '$light'
            },
            medium: {
                fontWeight: '$medium'
            },
            normal: {
                fontWeight: '$normal'
            },
            lightBold: {
                fontWeight: '$lightBold'
            },
            bold: {
                fontWeight: '$bold'
            }
        },
        flex: {
            true: {
                display: 'flex'
            }
        },
        inline: {
            true: {
                display: 'inline'
            }
        },
        underline: {
            true: {
                textDecoration: 'underline'
            }
        },
        userSelectNone: {
            true: { userSelect: 'none' }
        },
        nonShrink: {
            true: {
                display: 'flex',
                flexShrink: '0'
            }
        }
    },
    defaultVariants: {
        size: '3',
        weight: 'normal'
    }
})
