import { ComponentProps } from 'react'
import ReactPaginate, { ReactPaginateProps } from 'react-paginate'
import { CSS, styled } from '../../theme/stitches.config'
import { ArrowLeft, ArrowRight } from '../Svgs'

const DEFAULT_TAG = 'div'
type PaginationProps = {
    pageCount: number
    pageRangeDisplayed: number
    marginPagesDisplayed: number
    initialPage?: number
    forcePage?: number
    onPageChange(page: { selected: number }): void
} & ComponentProps<typeof DEFAULT_TAG> &
    ReactPaginateProps & { css?: CSS; as?: any }

const StyledPagination = styled('div', {
    '.pagination': {
        width: '100%',
        display: 'flex',
        alignItems: 'center'
    },

    '.pagination svg': {
        width: '8rem'
    },

    '.pagination li': {
        height: '30rem',
        width: '30rem',
        m: '0 2.5rem',
        color: '$boston',
        borderRadius: '$round',
        position: 'relative',
        cursor: 'pointer'
    },

    '.pagination li:hover': {
        background: '$navy',
        color: '$white'
    },

    '.pagination li.previous:hover,.pagination li.next:hover': {
        background: 'none'
    },

    '.pagination li.selected': {
        background: '$navy',
        color: '$white'
    },

    'li.previous.disabled, li.next.disabled': {
        pointerEvents: 'none',
        '& svg path': {
            stroke: '$nobel'
        }
    },

    '.pagination li a': {
        fontSize: '$3',
        position: 'absolute',
        top: '0',
        right: '0',
        left: '0',
        bottom: '0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        outline: 'none',
        cursor: 'pointer',
        fontWeight: 'bold'
    }
})

export const Pagination = ({
    pageCount,
    pageRangeDisplayed,
    marginPagesDisplayed,
    initialPage,
    forcePage,
    onPageChange
}: PaginationProps) => {
    return (
        <StyledPagination>
            <ReactPaginate
                pageCount={pageCount}
                forcePage={forcePage}
                pageRangeDisplayed={pageRangeDisplayed}
                marginPagesDisplayed={marginPagesDisplayed}
                initialPage={initialPage && initialPage - 1}
                previousLabel={<ArrowLeft />}
                nextLabel={<ArrowRight />}
                containerClassName="pagination"
                disabledClassName="disabled"
                onPageChange={onPageChange}
            />
        </StyledPagination>
    )
}
